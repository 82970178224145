import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { navigate } from 'gatsby';
import { useQuery } from '@apollo/react-hooks';

import { Container, Menu, MenuItem } from '@material-ui/core';
import SortIcon from '@material-ui/icons/Sort';

import { useDispatch } from 'react-redux';
import { useMutation } from 'react-apollo';
import { Announcement, Inbox, Sms } from '@material-ui/icons';
import { isEmpty } from '../../utils/ObjectUtils';
import { PageContent } from '../Common/styled/PageContent';
import { CardWrapperUI } from '../Common/styled/CardWrapperUI';
import Loading from '../Common/Loading';
import { SectionBar } from '../../componentsUI/SectionBar';

import { GET_NOTIFICATIONS } from '../../graphql/queries';
import TableNotificationCollapse from '../../componentsUI/TableNotificationCollapse';
import { GetFormat } from '../../utils/functions';
import { READ_NOTIFICATION } from '../../graphql/mutations';

const fieldNames = [
  { label: 'message', id: 'node.message', width: 600, format: 'MESSAGE' },
  { label: 'date', id: 'node.createdAt', width: 175, format: 'DATETIMEOPTION' },
];

export const NotificationsView = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [notificationsFilter, setNotificationsFilter] = useState('ALL');
  const openMenu = Boolean(anchorEl);

  // eslint-disable-next-line no-unused-vars
  const { loading, error, data, refetch, fetchMore } = useQuery(
    GET_NOTIFICATIONS,
    {
      variables: {
        first: 50,
        read: notificationsFilter,
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    },
  );

  const [readNotification] = useMutation(READ_NOTIFICATION);
  const fetchMoreNotifications = (fetchMoreCb) => {
    const { endCursor } = data.notifications.pageInfo;
    fetchMore({
      query: GET_NOTIFICATIONS,
      variables: {
        first: 50,
        cursor: endCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult && fetchMoreResult.notifications.edges;

        return newEdges.length ? {
          notifications: {
            // eslint-disable-next-line no-underscore-dangle
            __typename: previousResult.notifications.__typename,
            totalCount: previousResult.notifications.totalCount,
            edges: [...previousResult.notifications.edges, ...newEdges],
            pageInfo: fetchMoreResult.notifications.pageInfo,
          },
        } : previousResult;
      },
    })
      .then(({ data: fetchMoreData }) => fetchMoreData.notifications.pageInfo.hasNextPage && fetchMoreCb());
  };

  const handleGoto = (evt, index) => {
    evt.stopPropagation();
    const node = data.notifications.edges[index].node;

    readNotification({ variables: { uuid: node.uuid } })
      .then(({ data: dataUpdate }) => {
        refetch();
        if (dataUpdate.readNotification.hospital) {
          dispatch({ type: 'CHANGE_HOSPITAL', current: dataUpdate.readNotification.hospital });
        }
        // window.location.assign(node.link);
        navigate(node.link);
      })
      .catch(() => {
        navigate(node.link);
      });
  };

  const getMessage = (item) => (
    `${GetFormat(item.node.message, fieldNames[0].format)}`
  );

  const getDateTime = (item) => (
    `${GetFormat(item.node.createdAt, fieldNames[1].format)}`
  );

  const isSelected = (option) => option === notificationsFilter;

  const handleSelect = (option) => {
    if (isSelected(option)) return;
    setNotificationsFilter(option);
    refetch();
  };

  const handleOrder = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const buttons = [
    { name: 'show.not.read.notifications', icon: Announcement, handleClick: () => handleSelect('FALSE'), disabled: false, selected: isSelected('FALSE') },
    { name: 'show.read.notifications', icon: Sms, handleClick: () => handleSelect('TRUE'), disabled: false, selected: isSelected('TRUE') },
    { name: 'show.all.notifications', icon: Inbox, handleClick: () => handleSelect('ALL'), disabled: false, selected: isSelected('ALL') },
    { name: 'divider2', type: 'divider' },
    { name: 'sort.by', icon: SortIcon, handleClick: () => {}, disabled: true },
  ];

  return (
    <>
      <Container maxWidth="xl">

        <Menu anchorEl={anchorEl} open={openMenu} onClose={() => setAnchorEl(null)}>
          {fieldNames.filter((filter) => !!filter.field).map((item) => (
            <MenuItem
              key={item.id}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <span style={{ width: 150 }}>{t(item.label)}</span>
            </MenuItem>
          ))}
        </Menu>

        <SectionBar title="notifications" items={buttons} />
        <PageContent>
          { loading && isEmpty(data) && <Loading /> }

          <CardWrapperUI>
            {
              (data && data.notifications)
              && (
                <TableNotificationCollapse
                  fieldNames={fieldNames}
                  items={data.notifications.edges}
                  GetMessage={getMessage}
                  GetDateTime={getDateTime}
                  handleGoto={handleGoto}
                  isMore={data
                  && data.notifications
                  && data.notifications.pageInfo
                  && data.notifications.pageInfo.hasNextPage}
                  fetchMoreFn={fetchMoreNotifications}
                  order={{ field: 'CREATED_AT', direction: 'DESC' }}
                  handleOrder={handleOrder}
                />
              )
            }
          </CardWrapperUI>
        </PageContent>
      </Container>
    </>
  );
};
