import React, { useState, createRef } from 'react';

import Collapse from '@material-ui/core/Collapse';
import LoadMore from '../components/Pagination/LoadMore';
import { useStyles } from './styles/TableCollapse';
import { HeaderFields, ItemFields, ItemFieldsResponsive } from './TableNotificationCollapseItems';
import { useWindowSize } from '../utils/useWindowsSize';
import { Span } from '../components/Common/styled/Text';

const WIDTHMAX = 960;

const TableNotificationCollapse = (props) => {
  const { fieldNames, items, GetMessage, GetDateTime, GetCollapse, responsive } = props;
  const { handleGoto } = props;
  const { isMore, fetchMoreFn, order, handleOrder } = props;

  const [width] = useWindowSize();
  const isResponsive = (responsive !== undefined) ? responsive : width >= WIDTHMAX;

  const classes = useStyles(isResponsive);
  const parentRef = createRef(null);
  const [posLeft, setPosLeft] = useState(0);

  const handleScroll = () => {
    if (-parentRef.current.scrollLeft !== posLeft) {
      setPosLeft(-parentRef.current.scrollLeft);
    }
  };

  const childrenProps = { classes, isResponsive, fieldNames, GetMessage, GetDateTime, handleGoto, posLeft, order, handleOrder };

  return (
    <div className={classes.table}>
      {!!isResponsive && <HeaderFields {...childrenProps} />}
      <div className={classes.tableItemsScroll} ref={parentRef} onScroll={handleScroll}>
        <div className={classes.tableItems}>

          {items.map((item, index) => (
            <div key={index.toString()} className={classes.itemNotification} aria-hidden="true">
              <div className={classes.tableItem}>
                <Span style={{ display: 'flex', width: '100%' }} onClick={(evt) => handleGoto(evt, index)}>
                  {isResponsive
                    ? <ItemFields {...childrenProps} index={index} item={item} multiline />
                    : <ItemFieldsResponsive {...childrenProps} index={index} item={item} />}
                </Span>
              </div>

              <Collapse className={classes.collapse} timeout="auto" unmountOnExit>
                <div style={{ display: 'flex' }}>
                  {GetCollapse && <GetCollapse item={item} fieldNames={fieldNames} /> }
                </div>
              </Collapse>
            </div>
          ))}

          {isMore && <LoadMore container parent={parentRef} fetchMoreFn={fetchMoreFn} />}
        </div>
      </div>
    </div>
  );
};

export default TableNotificationCollapse;
