import React from 'react';
import { useTranslation } from 'react-i18next';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { Announcement, Sms } from '@material-ui/icons';
import { GetValue } from '../utils/functions';
import { Row } from '../components/Common/styled/Groups';
import { InnerSpanHTML } from '../components/Common/InnerHTML';

const OrderIcon = ({ className, direction }) => (
  (direction === 'ASC') ? <ArrowDropDownIcon className={className} /> : <ArrowDropUpIcon className={className} />
);

const NotificationIcon = ({ item }) => (
  <>
    {item && item.node && !item.node.readAt && (<Announcement />)}
    {item && item.node && !!item.node.readAt && (<Sms />)}
  </>
);

export const HeaderFields = ({ classes, fieldNames, posLeft, order, handleOrder }) => {
  const { t } = useTranslation();

  return (
    <div className={classes.tableHeaderScroll}>
      <div className={classes.tableHeader} style={{ left: posLeft }}>
        <div style={{ width: 0 }} />
        {fieldNames.map((item, index) => (item.width > 0
          ? (
            <div
              key={index.toString()}
              className={classes.tableField}
              style={{ width: item.width }}
              onClick={() => handleOrder(item)}
              aria-hidden="true"
            >
              <span>{t(item.label)}</span>
              {(order && item.field === order.field) && <OrderIcon direction={order.direction} className={classes.orderIcon} />}
            </div>
          )
          : undefined))}
        <></>
      </div>
    </div>
  );
};

export const ItemFieldsResponsive = ({ classes, item, GetMessage, GetDateTime }) => {
  const messageClassName = item && item.node && item.node.readAt !== undefined ? 'message' : 'item-no-message';
  const notReadClassName = messageClassName && !item.node.readAt ? 'message-not-read' : 'message-read';
  return (
    <div className={classes.headerNotificationCollapse}>
      <span className={`${classes.title} ${messageClassName} ${notReadClassName}`}>
        <InnerSpanHTML content={!!GetMessage && GetMessage(item)} />
      </span>
      <div className={`${classes.subtitle} ${notReadClassName}`} style={{ display: 'flex', alignItems: 'center' }}>
        <NotificationIcon item={item} />
        <span>
          {!!GetDateTime && GetDateTime(item)}
        </span>
      </div>
    </div>
  );
};

export const ItemFields = ({ classes, fieldNames, item, multiline }) => {
  const className = multiline ? classes.multilineItemField : classes.itemField;
  const messageClassName = item && item.node && item.node.readAt !== undefined ? 'message' : 'item-no-message';
  const notReadClassName = messageClassName && !item.node.readAt ? 'message-not-read' : 'message-read';
  return (
    <>
      {fieldNames.map((col, i) => ((col.width > 0) && (
        <span key={i.toString()} className={className} style={{ width: col.width }}>
          {col.format && col.format === 'MESSAGE' ? (
            <Row className={notReadClassName}>
              <div className={classes.notificationIconWrapper}>
                <NotificationIcon item={item} />
              </div>
              <InnerSpanHTML content={GetValue(item, col)} />
            </Row>
          ) : (
            <InnerSpanHTML content={GetValue(item, col)} />
          )}
        </span>
      )))}
    </>
  );
};
