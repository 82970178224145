import React from 'react';

import Layout from '../../components/MenuLayout';
import { NotificationsView } from '../../components/Notification/NotificationsView';

export default () => (
  <Layout>
    <NotificationsView />
  </Layout>
);
